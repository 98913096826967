<template>
  <div class="w-100">
    <router-view id="app" class="w-100 flex-grow-1"></router-view>
    <error-alert-modal-component></error-alert-modal-component>
    <success-alert-modal-component></success-alert-modal-component>
  </div>
</template>

<script>
import SuccessAlertModalComponent from "./components/SuccessAlertModalComponent.vue";
import ErrorAlertModalComponent from "./components/ErrorAlertModalComponent.vue";

export default {
  name: "App",
  components:{
    SuccessAlertModalComponent,
    ErrorAlertModalComponent,
  },
};
</script>

<style>
[title] {
  cursor: help;
}
.table th,
.table tbody tr:first-of-type td {
  border-top: 0 !important;
}
.table td {
  vertical-align: middle !important;
}
.table td, .table th {
	padding: 1rem !important;
}
.table th {
  font-weight: normal;
  font-size: .8rem;
  color: #6c757d;
}
.table { 
  z-index: 1;
}
</style>

