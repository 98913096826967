<template>
  <div class="d-flex justify-content-center align-items-center bg-white my-5" style="height: 100vh;" v-if="isLoading">
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>
  <div v-else>
    <b-table
      ref="table"
      :items="registrations"
      :fields="fields"
      class="mb-0"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      :empty-text="'Es sind keine offenen Registrierungen vorhanden'"
      sort-icon-left
    >
      <template #cell(companyName)="data">
        {{ data.item.docData.companyProfile.companyName }}
      </template>
      <template #cell(customernumber)="data">
        {{ data.item.docData.account.customernumber }}
      </template>
      <template #cell(registrationCreationDate)="data">
        {{ data.item.docData.account.registrationCreationDate | date }} {{ data.item.docData.account.registrationCreationDate | time }}
      </template>
      <template #cell(confirmedByResellerDate)="data">
        <b-button
          variant="outline-primary"
          size="sm"
          v-if="data.item.docData.account.registrationCreationDate !== '' && data.item.docData.account.confirmedByResellerDate === ''"
          v-on:click="openRecipientModal(data.item.docData, data.item.docId, 'reseller-confirmation')"
        >
          Bestätigungs E-Mail erneut senden
        </b-button>
        
        <span v-else>
          {{ data.item.docData.account.confirmedByResellerDate | date }}
          {{ data.item.docData.account.confirmedByResellerDate | time }}
        </span>
      </template>
      <template #cell(confirmedByDistributorDate)="data">
        <b-button
          variant="outline-primary"
          size="sm"
          v-if="
            data.item.docData.account.registrationCreationDate !== '' && 
            data.item.docData.account.confirmedByResellerDate !== '' &&
            data.item.docData.account.confirmedByDistributorDate === ''
          "
          v-on:click="resendDistributorConfirmation(data.item.docData, data.item.docId)"
        >
          Bestätigungs E-Mail erneut senden
        </b-button>
        <span v-else>
          {{ data.item.docData.account.confirmedByDistributorDate | date }}
          {{ data.item.docData.account.confirmedByDistributorDate | time }}
        </span>
      </template>
      <template #cell(accountCreated)="data">
        <b-button
          variant="outline-primary"
          size="sm"
          v-if="
            data.item.docData.account.registrationCreationDate !== '' &&
            data.item.docData.account.confirmedByResellerDate !== '' &&
            data.item.docData.account.confirmedByDistributorDate !== ''
          "
          v-on:click="openRecipientModal(data.item.docData, data.item.docId, 'account-creation')"
        >
          Zugang erstellen E-Mail erneut senden
        </b-button>
      </template>
      <template #cell(deleteRegistration)="data">
        <div class="d-flex justify-content-end">
          <b-button
            variant="outline-danger"
            size="sm"
            v-on:click="deleteRegistration(data.item.docId)"
          >
            Löschen
          </b-button>
        </div>
      </template>
    </b-table>

    <b-modal
      ref="recipient-modal"
      hide-header
      no-close-on-esc
      no-close-on-backdrop
      :content-class="contentClass"
      :footer-class="footerClass"
      :body-class="bodyClass"
      v-on:hidden="closedRecipientModal()"
    >
      <label>Empfänger</label>
      <b-input v-model="recipientEmail" :state="states.recipientEmail"></b-input>
      
      <template #modal-footer="{ cancel }">
        <b-button variant="outline-secondary" v-on:click="cancel()">
          Abbrechen
        </b-button>

        <b-button v-show="messageType === 'reseller-confirmation'" v-on:click="resendResellerConfirmation(recipientRegistrationData, recipientRegistrationId)" :disabled="states.recipientEmail !== true" variant="primary">
          Senden
        </b-button>
        <b-button v-show="messageType === 'account-creation'" v-on:click="resendResellerAccountCreationInformation(recipientRegistrationData, recipientRegistrationId)" :disabled="states.recipientEmail !== true" variant="primary">
          Senden
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { firestore, functions } from "../plugins/firebase"
import { doc, deleteDoc, collection, getDocs } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import isEmail from "validator/es/lib/isEmail";

export default {
  name: "RegsitrationsView",
  data() {
    return {
      recipientEmail: "",
      states: {
        recipientEmail: null,
      },
      messageType: null,
      recipientRegistrationData: null,
      recipientRegistrationId: "",
      contentClass: ["border-0", "shadow-lg"],
      footerClass: ["border-0 bg-light p-4"],
      bodyClass: ["p-4"],
      headerClass: ["border-0 bg-light px-4"],
      isLoading: true,
      registrations: [],
      sortBy: "registrationCreationDate",
      sortDesc: true,
      showOffCanvasPanel: false,
      fields: [
        {
          key: "companyName",
          label: "Reseller",
          sortable: false,
        },
        {
          key: "customernumber",
          label: "Kundennummer",
          sortable: false,
        },
        {
          key: "registrationCreationDate",
          label: "Erstellt",
          sortable: false,
        },
        {
          key: "confirmedByResellerDate",
          label: "Vom Reseller bestätigt",
          sortable: false,
        },
        {
          key: "confirmedByDistributorDate",
          label: "Vom Distributor bestätigt",
          sortable: false,
        },
        {
          key: "accountCreated",
          label: "",
          sortable: false,
        },
        {
          key: "deleteRegistration",
          label: "",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getRegistrations();
  },
  computed: {
    tableHeight() {
      return window.innerHeight - 64 - 71 + "px";
    },
  },
  watch: {
    recipientEmail(email) {
      if (email === "") {
        this.states.recipientEmail = null;
      } else {
        this.states.recipientEmail = isEmail(email);
      }
    },
  },
  methods: {
    closedRecipientModal() {
      this.recipientRegistrationData = null;
      this.recipientRegistrationId = "";
      this.recipientEmail = "";
      this.messageType = null;
    },
    openRecipientModal(docData, docId, messageType) {
      this.recipientRegistrationData = docData;
      this.recipientRegistrationId = docId;
      this.recipientEmail = docData.account.email;
      this.messageType = messageType;
      this.$refs["recipient-modal"].show();
    },
    async getRegistrations() {
      try {
        const querySnapshot = await getDocs(collection(firestore, "registrations"));
        querySnapshot.forEach((doc) => {
          this.registrations.push({
            docId: doc.id,
            docData: doc.data(),
          });
        });

        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    async resendResellerConfirmation(registration, registrationId) {
      const resendResellerConfirmation = httpsCallable(functions, "dashboard-resendResellerConfirmation");
      await resendResellerConfirmation({
        registrationId: registrationId,
        registration: registration,
        recipientEmail: this.recipientEmail,
      })
        .then(() => {
          this.$refs["recipient-modal"].hide();
          this.$store.dispatch("alertSuccess", {
            message: "Die E-Mail wurde versandt.",
            show: true,
            noFooter: false,
          })
        })
        .catch((error) => {
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });
    },
    async resendDistributorConfirmation(registration, registrationId) {
      const resendDistributorConfirmation = httpsCallable(functions, "dashboard-resendDistributorConfirmation");
      await resendDistributorConfirmation({
        registrationId: registrationId,
        registration: registration,
        confirmationCode: registration.confirmationCode,
      })
        .then(() => {
          this.$refs["recipient-modal"].hide();
          this.$store.dispatch("alertSuccess", {
            message: "Die E-Mail wurde versandt.",
            show: true,
            noFooter: false,
          })
        })
        .catch((error) => {
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });
    },
    async resendResellerAccountCreationInformation(registration, registrationId) {
      const resendResellerAccountCreationInformation = httpsCallable(functions, "dashboard-resendResellerAccountCreationInformation");
      await resendResellerAccountCreationInformation({
        registrationId: registrationId,
        registration: registration,
        recipientEmail: this.recipientEmail,
      })
        .then(() => {
          this.$refs["recipient-modal"].hide();
          this.$store.dispatch("alertSuccess", {
            message: "Die E-Mail wurde versandt.",
            show: true,
            noFooter: false,
          })
        })
        .catch((error) => {
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });
    },
    async deleteRegistration(docId) {
      try {
        await deleteDoc(doc(firestore, "registrations", docId));

        this.$refs.table.refresh();
        this.$store.dispatch("alertSuccess", {
          message: "Die Registrierung wurde gelöscht.",
          show: true,
          noFooter: false,
        })
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped>
table {
  margin-top: 64px;
}
</style>