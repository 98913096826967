<template>
  <div>
  <div class="d-flex justify-content-center align-items-center bg-white my-5" style="height: 100vh;" v-if="data.length <= 0 ">
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>

  <b-card v-else no-body class="rounded-0">
    <b-table
      :items="data"
      class="mb-0"
      sort-icon-left
      ref="table"
      >

      <template #cell(offerId)="data">
        {{ data.item.offerId }}
      </template>

      <template #cell(name)="data">
        <p v-if="data.item.name">{{ data.item.name }}</p>
        <b-button
          @click="openModalSetName(data.item)"
          variant="warning"
          size="sm" v-else>Eintragen</b-button>
      </template>

      <template #cell(Bearbeiten)="data">
        <b-button
          @click="openModalSetName(data.item)"
          variant="outline-primary"
          size="sm">Bearbeiten</b-button>
      </template>

    </b-table>
    <template #footer>
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column align-items-center text-muted">
          {{ data.length }} HVD Kunden
        </div>
      </div>
    </template>
  </b-card>

  <b-modal
      id="set-price-modal"
      ref="set-price-modal"
      no-close-on-esc
      no-close-on-backdrop
      title="Name"
      size="l"
      v-on:ok="save()"
    >
    <div class="d-flex align-items-center mb-3">
      <span class="mr-1 nameOfProduct">OfferId</span>
      <div style="width: 300px; padding-left: 0px;">Name</div>
    </div>
    <hr>

    <div class="d-flex align-items-center mb-3">
      <div class="mr-3 nameOfProduct">{{ offerId }}</div>
      <b-input type="text" v-model="name"></b-input>
      <span class="mx-3"></span>
    </div>

    <template #modal-footer="{ cancel, ok }">
      <b-button variant="outline-secondary" v-on:click="cancel()"> Abbrechen </b-button>
      <b-button variant="primary" v-on:click="ok()"> Speichern </b-button>
    </template>

    </b-modal>
  </div>
</template>

<script>
import { firestore } from "../plugins/firebase"
import { collection, getDocs, updateDoc, doc, } from "firebase/firestore";


export default {
  components: { },
  name: "ProductNamesView",
  data() {
    return {
     currentData: {},
     data: [],
     offerId: "",
     name: ""
    };
  },
  async mounted() {
    await this.getNames();

  },
  computed: {
    tableHeight() {
      return window.innerHeight - 64 - 71 - 51 + "px";
    },
  },
  methods: {
    async getNames(){
      const querySnapshot = await getDocs(collection(firestore, "productNames"));
      querySnapshot.forEach((doc) => {
        this.data.push({offerId: doc.id, name: doc.data().name, Bearbeiten: doc.id })
      })
    },
    openModalSetName(data){
      console.log(data)
      this.name = data.name || '';
      this.offerId = data.offerId;
      this.$refs["set-price-modal"].show();
    },
    save(){
      const index = this.data.findIndex(el=>el.offerId === this.offerId)
      updateDoc(doc(firestore, "productNames", this.offerId), {name: this.name})
      .then(()=>{
        this.data[index].name = this.name;
        this.$store.dispatch("alertSuccess", {
            message: "Fertig",
            show: true,
            noFooter: false,
          })
        })
      .catch((error) => {

          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });

      console.log(this.name)
    },
    statusClass(status) {
      if (status === true) {
        return "text-success";
      } else {
        return "text-danger";
      }
    },
    statusIcon(status) {
      if (status === true) {
        return "fa-circle-check";
      } else {
        return "fa-circle-xmark";
      }
    },
  },
};
</script>
<style scoped>
table {
  margin-top: 64px;
}
input { width: 300px;
}
.nameOfProduct {
  width: 200px;
}
</style>