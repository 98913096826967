<template>
  <div>
  <div class="d-flex justify-content-center align-items-center bg-white my-5" style="height: 100vh;" v-if="data.length <= 0 ">
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>

  <b-card v-else no-body class="rounded-0">
    <b-table
      :items="data"
      class="mb-0"
      sort-icon-left
      ref="table"
      >
      <template #cell(prices)="data">
        <b-button
          @click="openModalSetPrices(data)"
          variant="outline-primary"
          size="sm">Preise Eintargen</b-button>
      </template>
      <template #cell(created)="data">
        {{ data.item.created.toDate() | date }}
      </template>
      <template #cell(finished)="data">
        <fa-icon
          :icon="['fa-regular', statusIcon(data.item.finished )]"
          :class="`mr-1 ${statusClass(data.item.finished )}`"
        ></fa-icon>
      </template>
    </b-table>
    <template #footer>
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column align-items-center text-muted">
          {{ data.length }} HVD Kunden
        </div>
      </div>
    </template>
  </b-card>

  <b-modal
      id="set-price-modal"
      ref="set-price-modal"
      no-close-on-esc
      no-close-on-backdrop
      title="HVD Preise"
      size="xl"
      v-on:ok="save()"
    >
    <div class="d-flex align-items-center mb-3">
      <span class="mr-1 nameOfProduct">CL-Art.</span>
      <div style="width: 300px; padding-left: 0px;">EK <small>(unser Preis bei Adobe)</small></div>
      <span class="mx-2"></span>
      <div  style="width: 300px; padding-left: 0px;">VK <small>(HEK "Händler Einkaufspreis")</small></div>
      <span class="mx-2"></span>
      <div>UVP</div>
    </div>
    <hr>

    <div class="d-flex align-items-center mb-3" v-if="lvl <= 17 || prices['65322649CA13A12'].ek != '' || prices['65322649CA13A12'].vk !=''">
      <div class="mr-3 nameOfProduct"> Pro for enterprise<br><small>65322649CA13A12</small></div>
      <b-input type="number" v-model="prices['65322649CA13A12'].ek"></b-input>
      <span class="mx-3"></span>
      <b-input type="number" v-model="prices['65322649CA13A12'].vk"></b-input>
      <span class="mx-3"></span>
      <b-input type="number" v-model="prices['65322649CA13A12'].uvp"></b-input>
    </div>

    <div class="d-flex align-items-center mb-3">
      <div class="mr-3 nameOfProduct"> Pro for enterprise<br><small>65322649CA14A12</small></div>
        <b-input type="number" v-model="prices['65322649CA14A12'].ek"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65322649CA14A12'].vk"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65322649CA14A12'].uvp"></b-input>
    </div>

    <div class="d-flex align-items-center mb-3" v-if="lvl <= 17 || prices['65322648CA13A12'].ek != '' || prices['65322648CA13A12'].vk !=''">
      <div class="mr-3 nameOfProduct"> Pro for enterprise<br><small>65322648CA13A12</small></div>
        <b-input type="number" v-model="prices['65322648CA13A12'].ek"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65322648CA13A12'].vk"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65322648CA13A12'].uvp"></b-input>
    </div>

    <div class="d-flex align-items-center mb-3">
      <div class="mr-3 nameOfProduct"> Pro for enterprise<br><small>65322648CA14A12</small></div>
        <b-input type="number" v-model="prices['65322648CA14A12'].ek"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65322648CA14A12'].vk"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65322648CA14A12'].uvp"></b-input>
    </div>

    <div class="d-flex align-items-center mb-3" v-if="lvl <= 17 || prices['65304522CA13A12'].ek != '' || prices['65304522CA13A12'].vk !=''">
      <div class="mr-3 nameOfProduct"> Pro for teams<br><small>65304522CA13A12</small></div>
        <b-input type="number" v-model="prices['65304522CA13A12'].ek"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65304522CA13A12'].vk"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65304522CA13A12'].uvp"></b-input>
    </div>

    <div class="d-flex align-items-center mb-3">
      <div class="mr-3 nameOfProduct"> Pro for teams<br><small>65304522CA14A12</small></div>
        <b-input type="number" v-model="prices['65304522CA14A12'].ek"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65304522CA14A12'].vk"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65304522CA14A12'].uvp"></b-input>
    </div>

    <div class="d-flex align-items-center mb-3" v-if="lvl <= 17 || prices['65304521CA13A12'].ek != '' || prices['65304521CA13A12'].vk !=''">
      <div class="mr-3 nameOfProduct"> Pro for teams<br><small>65304521CA13A12</small></div>
        <b-input type="number" v-model="prices['65304521CA13A12'].ek"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65304521CA13A12'].vk"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65304521CA13A12'].uvp"></b-input>
    </div>

    <div class="d-flex align-items-center mb-3">
      <div class="mr-3 nameOfProduct"> Pro for teams<br><small>65304521CA14A12</small></div>
        <b-input type="number" v-model="prices['65304521CA14A12'].ek"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65304521CA14A12'].vk"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65304521CA14A12'].uvp"></b-input>
    </div>

    <div class="d-flex align-items-center mb-3" v-if="lvl <= 17 || prices['65322603CA13A12'].ek != '' || prices['65322603CA13A12'].vk !=''">
      <div class="mr-3 nameOfProduct"> Standard for enterprise<br><small>65322603CA13A12</small></div>
        <b-input type="number" v-model="prices['65322603CA13A12'].ek"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65322603CA13A12'].vk"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65322603CA13A12'].uvp"></b-input>
    </div>

    <div class="d-flex align-items-center mb-3">
      <div class="mr-3 nameOfProduct"> Standard for enterprise<br><small>65322603CA14A12</small></div>
        <b-input type="number" v-model="prices['65322603CA14A12'].ek"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65322603CA14A12'].vk"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65322603CA14A12'].uvp"></b-input>
    </div>

    <div class="d-flex align-items-center mb-3" v-if="lvl <= 17 || prices['65322607CA13A12'].ek != '' || prices['65322607CA13A12'].vk !=''">
      <div class="mr-3 nameOfProduct"> Standard for enterprise<br><small>65322607CA13A12</small></div>
        <b-input type="number" v-model="prices['65322607CA13A12'].ek"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65322607CA13A12'].vk"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65322607CA13A12'].uvp"></b-input>
    </div>

    <div class="d-flex align-items-center mb-3">
      <div class="mr-3 nameOfProduct"> Standard for enterprise<br><small>65322607CA14A12</small></div>
        <b-input type="number" v-model="prices['65322607CA14A12'].ek"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65322607CA14A12'].vk"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65322607CA14A12'].uvp"></b-input>
    </div>

    <div class="d-flex align-items-center mb-3" v-if="lvl <= 17 || prices['65304888CA13A12'].ek != '' || prices['65304888CA13A12'].vk !=''">
      <div class="mr-3 nameOfProduct"> Standard for teams<br><small>65304888CA13A12</small></div>
        <b-input type="number" v-model="prices['65304888CA13A12'].ek"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65304888CA13A12'].vk"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65304888CA13A12'].uvp"></b-input>
    </div>

    <div class="d-flex align-items-center mb-3">
      <div class="mr-3 nameOfProduct"> Standard for teams<br><small>65304888CA14A12</small></div>
        <b-input type="number" v-model="prices['65304888CA14A12'].ek"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65304888CA14A12'].vk"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65304888CA14A12'].uvp"></b-input>
    </div>

    <div class="d-flex align-items-center mb-3" v-if="lvl <= 17 || prices['65304886CA13A12'].ek != '' || prices['65304886CA13A12'].vk !=''">
      <div class="mr-3 nameOfProduct"> Standard for teams<br><small>65304886CA13A12</small></div>
        <b-input type="number" v-model="prices['65304886CA13A12'].ek"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65304886CA13A12'].vk"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65304886CA13A12'].uvp"></b-input>
    </div>

    <div class="d-flex align-items-center mb-3">
      <div class="mr-3 nameOfProduct"> Standard for teams<br><small>65304886CA14A12</small></div>
        <b-input type="number" v-model="prices['65304886CA14A12'].ek"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65304886CA14A12'].vk"></b-input>
        <span class="mx-3"></span>
        <b-input type="number" v-model="prices['65304886CA14A12'].uvp"></b-input>
    </div>
      <template #modal-footer="{ cancel, ok }">
        <b-button variant="outline-secondary" v-on:click="cancel()"> Abbrechen </b-button>
        <b-button variant="primary" v-on:click="ok()"> Speichern </b-button>
      </template>

    </b-modal>
  </div>
</template>

<script>
import { firestore } from "../plugins/firebase"
import { collection, getDocs, updateDoc, doc, } from "firebase/firestore";


export default {
  components: { },
  name: "HVDPricesView",
  data() {
    return {
     currentData: {},
     data: [],
     lvl: 0,
     prices: {
        "65322649CA13A12": { vk: 0, ek: 0, uvp: 0 },
        "65322649CA14A12": { vk: 0, ek: 0, uvp: 0 },
        "65322648CA13A12": { vk: 0, ek: 0, uvp: 0 },
        "65322648CA14A12": { vk: 0, ek: 0, uvp: 0 },
        "65304522CA13A12": { vk: 0, ek: 0, uvp: 0 },
        "65304522CA14A12": { vk: 0, ek: 0, uvp: 0 },
        "65304521CA13A12": { vk: 0, ek: 0, uvp: 0 },
        "65304521CA14A12": { vk: 0, ek: 0, uvp: 0 },
        "65322603CA13A12": { vk: 0, ek: 0, uvp: 0 },
        "65322603CA14A12": { vk: 0, ek: 0, uvp: 0 },
        "65322607CA13A12": { vk: 0, ek: 0, uvp: 0 },
        "65322607CA14A12": { vk: 0, ek: 0, uvp: 0 },
        "65304888CA13A12": { vk: 0, ek: 0, uvp: 0 },
        "65304888CA14A12": { vk: 0, ek: 0, uvp: 0 },
        "65304886CA13A12": { vk: 0, ek: 0, uvp: 0 },
        "65304886CA14A12": { vk: 0, ek: 0, uvp: 0 }
      }
    };
  },
  async mounted() {
    await this.getHVDPrices();

  },
  computed: {
    tableHeight() {
      return window.innerHeight - 64 - 71 - 51 + "px";
    },
  },
  methods: {
    async getHVDPrices(){
      const querySnapshot = await getDocs(collection(firestore, "hvdPrices"));
      querySnapshot.forEach((doc) => {
        this.data.push(doc.data())
      })
    },
    openModalSetPrices(data){
      this.currentData = data.item;
      const lvl = data.item.discountCode.split('_')[1].replace("L", "")
      this.lvl = lvl;
      data.item.prices && (this.prices = data.item.prices)

      this.$refs["set-price-modal"].show();
    },
    save(){
      Object.entries(this.prices).forEach(el=>{
        Object.entries(el[1]).forEach(subEl=>{
          this.prices[el[0]][subEl[0]] = Number(subEl[1].toString().replace(",", "."))
        })
      })
console.log(this.currentData)
      updateDoc(doc(firestore, "hvdPrices", this.currentData.customerId), {prices: this.prices, finished: true})
      .then(()=>{
        this.$store.dispatch("alertSuccess", {
            message: "Fertig",
            show: true,
            noFooter: false,
          })
        })
        .catch((error) => {

            this.$store.dispatch("alertError", {
              message: error.message,
              show: true,
              noFooter: false,
            })
          });

      console.log(this.prices)
    },
    statusClass(status) {
      if (status === true) {
        return "text-success";
      } else {
        return "text-danger";
      }
    },
    statusIcon(status) {
      if (status === true) {
        return "fa-circle-check";
      } else {
        return "fa-circle-xmark";
      }
    },
  },
};
</script>
<style scoped>
table {
  margin-top: 64px;
}
input { width: 300px;
}
.nameOfProduct {
  width: 200px;
}
</style>